.Step {
    position: relative;
    font-weight: 600;
    font-size: 30px;
    line-height: 35px;

    color: #000000;

    margin-top: 10px;
    margin-bottom: 10px;
}

.StepLine {
    position: relative;
    width: 644px;
    left: -660px;
    bottom: -23px;
    height: 2px;
    font-weight: 500;
    font-size: 25px;
    line-height: 29px;
    background-color: #000000;
}