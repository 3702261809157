.Header1 {
    width: 100%;

    font-weight: 500;
    font-size: 97px;
    line-height: 114px;
    color: #000000;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    padding-top: 110px;
    padding-bottom: 43px;
    white-space: pre;
    cursor: default;
}

.Header2 {
    margin: 125px auto 40px;
    font-weight: 700;
    font-size: 33px;
    line-height: 39px;
    color: #000000;
    text-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
    cursor: default;
}

.Header3 {
    margin: 25px;
    font-weight: 600;
    font-size: 30px;
    line-height: 35px;
    color: #000000;
    text-transform: uppercase;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.Footer {
    white-space: pre-line;
    text-align: center;
    font-weight: 400;
    font-size: 25px;
    line-height: 29px;
    color: #000000;
    margin: 25px 25px 90px;
}