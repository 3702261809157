.Slogan {
    writing-mode: vertical-rl;

    font-weight: 600;
    font-size: 27px;
    line-height: 32px;
    white-space: pre;
    color: #000000;
    transform: rotate(180deg);

    cursor: default;

    margin: 31px;
}