.SpotPrimary {
    position: absolute;
    width: 999px;
    height: 999px;
    background: rgba(22, 190, 252, 0.53);
    filter: blur(97px);
    border-radius: 1000px;
}

.SpotSecondary {
    position: absolute;
    width: 810px;
    height: 810px;
    background: rgba(17, 22, 125, 0.55);
    filter: blur(164px);
    border-radius: 1000px;
}