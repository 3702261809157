.Button {

    height: 90px;
    left: 784px;
    top: 855px;


    box-sizing: border-box;

    width: 554px;
    min-height: 104px;
    margin: 24px 0;

    background: #16BEFC;
    /*border: 2px solid #000000;*/
    box-shadow: 0px 1px 10px 5px rgba(77, 139, 232, 0.47);
    border-radius: 79px;

    font-weight: 500;
    font-size: 38px;
    line-height: 45px;

    color: #000000;

    text-decoration-line: unset;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

    display: flex;
    align-items: center;
    justify-content: center;

    cursor: pointer;
}

.Button:hover {
    border: 2px solid #0EADE8;
}
