.FlexBox {
    position: relative;
    height: 100%;
    width: 100%;

    display: flex;
    align-items: center;
    justify-content: center;
}

.Cell {
    z-index: 0;
    border-left: 4px solid #000000;
    border-bottom: 4px solid #000000;
    display: table-cell;
}