.SpoilerLine {
    width: 100%;
    height: 1px;

    background-color: rgba(0, 0, 0, 0.7);
}

.SpoilerHeader {
    /*height: 29px;*/
    /*margin: 25px 0px;*/

    font-weight: 600;
    font-size: 30px;
    line-height: 35px;
    display: flex;
    justify-content: space-between;

    color: #000000;
}

.SpoilerContent {
    font-weight: 400;
    font-size: 30px;
    line-height: 35px;
    overflow: hidden;
    padding-top: 20px;

    color: rgba(0, 0, 0, 0.8);
}

.SpoilerIndicator {
    width: 19px;
    height: 19px;

    border: 4px solid #000000;
    border-radius: 19px;
}

.SpoilerOuter {
    margin: 25px 25px;
}