.Logo {
    position: absolute;
    width: 62px;
    height: 62px;

    background: #0EADE8;
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 69px;

    display: flex;
    align-items: center;
    justify-content: center;
}

.LogoEye {
    position: relative;
    width: 31px;
    height: 31px;;

    border-radius: 69px;
    background: #FFFFFF;
}